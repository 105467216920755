window.TomSelect = require('tom-select');

document.addEventListener("turbolinks:load", () => {
  if (document.getElementsByClassName("tomselect").length > 0) {

    document.querySelectorAll(".tomselect-multiple").forEach((el) => {

      let options = { plugins: ['checkbox_options'] }

      if (el.getAttribute('data-maxoptions')) options.maxOptions = null

      if (el.getAttribute('data-render')) {
        options.render = {
      		option: function(item) {
        		return '<div><div style="display:inline-block;padding:6px"><div>'+item.text+'</div><div style="opacity:0.6;padding-top:3px">'+item.channel+'</div></div></div>'
      		},
      		item: function(item) {
        		return '<div>'+item.text+' <span style="font-size:10px;opacity:0.6">('+item.channel+')</span></div>'
      		}
    		}
      }

    	new TomSelect(el, options)
    })

    document.querySelectorAll(".tomselect").forEach((el) => {
      fetch(el.getAttribute("source")).then((response) => response.json()).then((data) => {
        const multiSelectList = document.getElementById(el.getAttribute("list"));
        const valueField = el.getAttribute("value_field") || 'name';
        const labelField = "label";

        const selected = el.getAttribute('value');
        const items = selected ? selected.split(',') : null;

        multiSelectList.innerHTML = '';

        const multiSelect = new TomSelect(el, {
        	options: data,
          items: items,
        	maxItems: null,
        	maxOptions: 1000,
        	valueField: valueField,
        	labelField: labelField,
        	searchField: labelField,
        	create: false,
          onChange(args) {
            multiSelect.close();
            multiSelectList.innerHTML = "";
            if (args.length == 0) return;

            var tags = ( Array.isArray(args) ? args : args.split(','));

            displayTags(multiSelect, multiSelectList, tags);
          },
        	render: {
            option(data, escape) {
              return `<div>${data.label}</div>`;
            },
          },
        });

        idempotentize();
        displayTags(multiSelect, multiSelectList, multiSelect.items);
      });
    });

    function displayTags(multiSelect, multiSelectList, values) {
      for (const value of values) {
        const cross = document.createElement("i");
        cross.onclick = function () { multiSelect.removeItem(`${value}`); };
        cross.classList.add('fe', 'fe-x', 'text-primary')
        const option = document.createElement("div");
        option.classList.add('badge', 'bg-primary-soft');
        option.innerHTML = multiSelect.options[value].label;
        option.append(cross);
        multiSelectList.append(option);
      }
    }

    // Not great but I didn't find a better way to do this :(
    function idempotentize() {
      if (document.querySelectorAll(".ts-wrapper.tomselected").length > 0) {
        document.querySelectorAll('.ts-wrapper').forEach((el) => {

          if (el.classList.contains('tomselected')) {
            el.classList.remove("ts-hidden-accessible");
            el.classList.remove("tomselected");
          } else {
            el.remove();
          }
        });
      };
    };

  }
});
